import React, { useContext, useState } from "react";
import { Link } from "gatsby";
import arrowLeft from "../../resources/images/arrow-left.svg";
import burger from "../../resources/images/burger.svg";
import pref from "../../resources/images/pref.svg";
import ToggleButton from "./toggle-button";
import {
  categories,
  slugs2name,
  slugs2url,
  subcat2urlpart,
} from "../../resources/categorie";
import { WishlistContext } from "../../context/wishlist-context";
import { LanguageContext } from "../../context/language-context";
import useProductDict from "../use-products-dict";
import t from "../../resources/translations";

const MenuDesk = ({ initMenuOpen, setIsWishlistOpen }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(initMenuOpen);
  const toggleMenu = () => {
    setIsMenuOpen((old) => !old);
  };
  const l = useContext(LanguageContext).locale;
  const prodsDict = useProductDict()[l];
  const prodsBySubcat = Object.values(prodsDict).reduce((obj, prods) => {
    prods.forEach((p) => {
      const subcat = p.content.cat;
      !(subcat in obj) && (obj[subcat] = []);
      obj[subcat].push(p);
    });
    return obj;
  }, {});
  //console.log(Object.keys(prodsBySubcat));
  return (
    <div className="bg-is-light-gray hidden md:block">
      <div className="is-cont relative is-comm-flex py-3.5">
        <div className="flex-none w-1/4" />
        <ul className="px-20 flex-grow flex gap-4 text-white is-p">
          <li>
            <Link
              to={t.abLink[l]}
              className="hover:bg-is-bg-prodotti rounded-small px-2 py-1"
            >
              {t.about[l]}
            </Link>
          </li>
          <li>
            <Link
              to={t.conLink[l]}
              className="hover:bg-is-bg-prodotti rounded-small px-2 py-1"
            >
              {t.contact[l]}
            </Link>
          </li>
        </ul>
        <button
          className="is-comm-flex"
          onClick={() => {
            setIsWishlistOpen(true);
          }}
        >
          <img
            src={arrowLeft}
            className="pr-3.5 text-white fill-current"
            alt=""
          />
          <WishlistButtonDesk />
        </button>
        {/* MAIN MENU */}
        <div
          className="absolute top-0 left-0 w-1/4"
          style={{ zIndex: "100000" }}
        >
          <div
            className="transition-all duration-500 overflow-y-hidden is-menublock bg-is-dark-gray ml-4"
            style={{ maxHeight: isMenuOpen ? "750px" : "60px" }}
          >
            <div className="py-2.5 is-comm-flex pl-6 pr-3">
              <img src={burger} alt="" />
              <h4 className="is-h4 flex-grow px-3 text-white">
                {t.catProd[l]}
              </h4>
              <ToggleButton
                type="big-black"
                isOpen={isMenuOpen}
                onClick={toggleMenu}
              />
            </div>
            <ul className="is-bg py-3.5 pb-36 bg-is-dark-gray text-white">
              {Object.entries(categories).map(([cat, subcats]) => (
                <li
                  key={cat}
                  className="py-3.5 pl-6 pr-4 is-comm-flex hover:bg-is-gray category-item"
                >
                  <Link to={slugs2url[cat][l]} className="is-p">
                    {slugs2name[cat][l]}
                  </Link>
                  {subcats.length > 0 && (
                    <>
                      <ToggleButton type="black" onClick={null} />
                      <div className="absolute is-submenu bg-is-gray subcategory-menu-desk flex-col flex-wrap items-start py-7 pl-14 content-start">
                        {subcats.map((subcat) => (
                          <div
                            className="pb-4 mr-10 w-40"
                            style={{
                              maxWidth:
                                90 / Math.ceil(subcats.length / 2) + "%",
                            }}
                            key={subcat}
                          >
                            <Link
                              to={`${slugs2url[cat][l]}${subcat2urlpart[subcat][l]}`}
                              className="is-p text-is-blue hover:underline"
                            >
                              {slugs2name[subcat][l]}
                            </Link>
                            <ul className="is-h5 mt-3.5">
                              {(prodsBySubcat[subcat] ?? [])
                                .slice(0, 5)
                                .map((p) => (
                                  <li key={p["_uid"]} className="mt-1">
                                    <Link
                                      to={`${slugs2url[cat][l]}/${p.slug}`}
                                      className="hover:underline"
                                    >
                                      {p?.content?.titolo ?? p.name}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const WishlistButtonDesk = () => {
  const { wishlistSize } = useContext(WishlistContext);
  return (
    <div className="w-8 relative">
      {wishlistSize > 0 && (
        <div className="bg-is-red-bright w-2 h-2 rounded-full absolute top-0 right-0" />
      )}
      <img src={pref} alt="preferiti" className="w-full" />
    </div>
  );
};

MenuDesk.defaultProps = {
  initMenuOpen: false,
};

export default MenuDesk;
