import slugify from "slugify";

export const parseStory = (story) => {
  let content = story.content;
  if ("content" in story && typeof story.content === "string") {
    content = JSON.parse(story.content);
  }
  return {
    ...story,
    content,
  };
};

export const generateSearchFilter = (query) => (p) => {
  const toks = slugify(query.toLowerCase()).split("-");
  const t = slugify(p?.content?.titolo ?? "").toLowerCase();
  const s = slugify(p?.content?.sottotitolo ?? "").toLowerCase();
  const n = slugify(p?.name ?? "").toLowerCase();
  const score = toks.reduce(
    (cum, tok) =>
      cum +
      (t.search(tok) >= 0 ? 3 : 0) +
      (s.search(tok) >= 0 ? 2 : 0) +
      (n.search(tok) >= 0 ? 1 : 0),
    0
  );
  //console.log(t + " - " + s + " - " + n + " score: " + score + " " + toks);
  return { ...p, score };
};
