import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import { WishlistContext } from "../../context/wishlist-context";
import t from "../../resources/translations";
import FormFields from "./form-fields";
import close from "../../resources/images/close-white.svg";
import { cat2bgImg } from "../../resources/images";
import { attr2icon } from "../../resources/component-mapping";
/* global dataLayer */

const InviaRichiesta = () => {
  const { isInviaRichiestaOpen, setInviaRichiesta, wishlist } =
    useContext(WishlistContext);
  const [isSubmitted, setSubmitted] = useState(false);

  const l = useContext(LanguageContext).locale;

  const submit = async (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    data.sheet = "richieste";
    data.prodotti = Object.values(wishlist)
      .map(
        (p) =>
          `${p.titolo ?? p.name} [${Object.entries(p)
            .filter(([attr]) => attr in attr2icon)
            .map(([attr, val]) => `${t[attr].it}: ${val}`)
            .join(", ")}]`
      )
      .join("\n");
    try {
      const res = await fetch(
        "https://hooks.zapier.com/hooks/catch/769761/bk2jgke/",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
    } catch (err) {
      console.log(err);
    }
    dataLayer &&
      dataLayer.push({
        event: "richiesta_contatto",
        ...data,
      });
    setSubmitted(true);
  };

  return (
    <Transition show={isInviaRichiestaOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[10000000] overflow-y-auto"
        onClose={() => {
          setInviaRichiesta(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md lg:max-w-screen-md my-8 overflow-hidden text-left align-middle transition-all shadow-xl bg-is-dark-gray rounded-is text-white py-4 px-4 md:py-5 md:px-10 relative z-10">
              <div className="flex justify-between items-start">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-bold text-is-blue mt-8 md:mt-0"
                >
                  {t.inviaRichiesta[l]}
                </Dialog.Title>
                <button
                  onClick={() => {
                    setInviaRichiesta(false);
                  }}
                >
                  <img src={close} alt="close" />
                </button>
              </div>
              {isSubmitted ? (
                <>
                  <div className="mt-2.5">
                    <p className="text-lg">{t.testoGrazieContatto[l]}</p>
                  </div>
                  <div className="mt-6 flex justify-center">
                    <button
                      className="is-button"
                      onClick={() => {
                        setInviaRichiesta(false);
                      }}
                    >
                      {t.hoCapito[l]}
                    </button>
                  </div>
                </>
              ) : (
                <form onSubmit={submit}>
                  <div className="mt-2.5">
                    <p className="text-lg">{t.testoInviaRichiesta[l]}</p>
                  </div>
                  <div className="mt-6">
                    <FormFields />
                  </div>
                  <div>
                    <div className="text-is-blue block my-2">
                      {t.preferiti[l]}
                    </div>
                    <div className="mt-3 flex flex-col gap-3">
                      {Object.entries(wishlist).map(([k, o]) => (
                        <WishlistElement key={k} k={k} o={o} />
                      ))}
                    </div>
                  </div>
                  <div className="mt-6 flex gap-10 items-start">
                    <button className="is-button">{t.invia[l]}</button>
                    <label className="flex gap-1 items-center text-sm">
                      <input type="checkbox" name="privacy" />
                      Privacy policy
                    </label>
                  </div>{" "}
                </form>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const WishlistElement = ({ k, o }) => {
  const l = useContext(LanguageContext).locale;
  return (
    <div className="px-2 5 py-1 border-t border-b border-is-bg-gray flex flex-wrap items-center gap-1.5">
      <div className="w-8 h-8">
        {o?.wishlistImg ? (
          <img
            src={o.wishlistImg.filename}
            alt={o.wishlistImg?.alt ?? ""}
            className="h-full"
          />
        ) : (
          cat2bgImg[o.cat]
        )}
      </div>
      <p className="text-sm mr-1.5 flex-grow-1">{o.titolo ?? o.name}</p>
      <div className="flex justify-center items-center flex-wrap gap-3">
        {Object.entries(o)
          .filter(([attr]) => attr in attr2icon)
          .map(([attr, val]) => (
            <div className="flex items-center gap-1.5">
              <img
                src={attr2icon[attr]}
                alt={t[attr][l]}
                className="w-[18px]"
              />
              <div className="p-1.5 border border-white text-white text-sm">
                {val}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default InviaRichiesta;
