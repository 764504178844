import { Listbox, Popover, Transition } from "@headlessui/react";
import React, { useContext, useState, useRef, useEffect } from "react";
import { LanguageContext } from "../../context/language-context";
import { categories, slugs2name, slugs2url } from "../../resources/categorie";
import filtri from "../../resources/filtri";
import t from "../../resources/translations";
import SearchButton from "./search-button";
import arrProd from "../../resources/images/arrow-down.svg";
import useProductDict from "../use-products-dict";
import { generateSearchFilter } from "../../utils";
import { Link } from "gatsby";
import { cat2bgImg } from "../../resources/images";

const SearchBarMob = () => {
  const [activeCat, setActiveCat] = useState("tutti-prod");
  const [searchData, setSearchData] = useState(null);
  const l = useContext(LanguageContext).locale;
  const searchButtonRef = useRef();
  const prodsDict = useProductDict();

  const [suggestedProds, setSuggestedProds] = useState([]);

  useEffect(() => {
    if (activeCat === "tutti-prod" && typeof searchData === "string") {
      if (searchData.length > 1) {
        const prods = Object.values(prodsDict[l]).flatMap((i) => i);
        const fnc = generateSearchFilter(searchData);
        const scoredProds = prods.map(fnc);
        scoredProds.sort((a, b) => (b?.score ?? 0) - (a?.score ?? 0));
        setSuggestedProds(
          scoredProds.filter((p, i) => i < 10 && (p?.score ?? 0) > 0)
        );
      } else if (searchData.length < 1) {
        setSuggestedProds([]);
      }
    }
  }, [activeCat, searchData, prodsDict]);

  const onEnter = (e) => {
    console.log("called");
    if (e.keyCode === 13) {
      console.log("got enter");
      e.preventDefault();
      searchButtonRef?.current && searchButtonRef.current.click();
    }
  };

  return (
    <Transition
      as="div"
      enter="transition ease-out duration-150"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
      className="w-full absolute top-full left-0 border-t border-is-blue"
    >
      <Popover.Panel className="bg-is-search-gray py-5 pl-4 pr-6 text-black">
        <div className="flex gap-4 items-end" onKeyUp={onEnter}>
          <div className="flex-grow flex flex-col gap-5">
            <Listbox
              value={activeCat}
              onChange={(v) => {
                setActiveCat(v);
                setSearchData(
                  v === "tutti-prod"
                    ? ""
                    : Object.keys(filtri[v]).reduce((obj, f) => {
                        f !== "categoria" &&
                          f !== "materiali" &&
                          (obj[f] = null);
                        return obj;
                      }, {})
                );
              }}
              as="div"
              className="relative"
            >
              <Listbox.Button
                className="text-is-dark-gray border-is-rev-duration border px-5 py-2 is-comm-flex gap-8 rounded-is w-full"
                style={{ height: "40px" }}
              >
                {activeCat === "tutti-prod"
                  ? t[activeCat][l]
                  : slugs2name[activeCat][l]}
                <img src={arrProd} alt="" />
              </Listbox.Button>
              <Listbox.Options
                className="bg-white border-is-range-slider border border-t-0 text-is-light-gray absolute right-4 z-10 flex flex-col"
                style={{ width: "calc(100% - 32px)" }}
              >
                {["tutti-prod", ...Object.keys(categories)]
                  .filter(
                    (v) =>
                      v !== "affilatura" &&
                      v !== "elettroutensili" &&
                      v !== "accessori"
                  )
                  .map((v, idx) => (
                    <Listbox.Option
                      key={v}
                      value={v}
                      className={
                        " py-2 border-is-range-slider border-t px-3 cursor-pointer hover:bg-gray-300 "
                      }
                    >
                      {v === "tutti-prod" ? t[v][l] : slugs2name[v][l]}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Listbox>
            {activeCat === "tutti-prod" ? (
              <label className="pl-6 text-black relative">
                <p className="hidden">Cerca un prodotto</p>
                <input
                  type="text"
                  placeholder="Search..."
                  autoComplete="off"
                  name="search"
                  className="focus:outline-none inline-block h-10 rounded-is px-5 border border-is-rev-duration"
                  value={searchData}
                  onChange={(e) => {
                    setSearchData(e.target.value);
                  }}
                />
                {suggestedProds && suggestedProds.length > 0 && (
                  <div className="absolute w-full top-full left-0 z-20 pl-10 pr-14">
                    <ul className="bg-is-dark-gray">
                      {suggestedProds.map((p) => {
                        const cat = p.field_component.replace("pagina-", "");
                        return (
                          <li className="px-2 py-0.5 border-t border-white first:border-t-0 hover:bg-is-light-gray">
                            <Link
                              to={`${slugs2url[cat][l]}/${p.slug}`}
                              className="text-white flex items-center gap-2"
                            >
                              <div className="w-9">
                                {p?.content?.imgs[0] ? (
                                  <img
                                    src={p?.content?.imgs[0].filename}
                                    alt={p?.content?.imgs[0]?.alt ?? ""}
                                  />
                                ) : (
                                  cat2bgImg[cat]
                                )}
                              </div>
                              {p?.content?.titolo ?? p?.name ?? ""}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </label>
            ) : (
              Object.entries(filtri[activeCat])
                .filter(([f, v]) => f !== "categoria" && f !== "materiali")
                .map(([f, v]) => (
                  <FiltInput
                    f={f}
                    v={v}
                    key={f}
                    name={t[f][l]}
                    onChange={(e) => {
                      setSearchData((old) => ({
                        ...old,
                        [f]: e.target.value,
                      }));
                    }}
                  />
                ))
            )}
          </div>
          <SearchButton
            {...{ activeCat, searchData, searchRef: searchButtonRef }}
          />
        </div>
      </Popover.Panel>
    </Transition>
  );
};

const FiltInput = ({ f, v, name, onChange }) => {
  switch (v.type) {
    case "range":
      return (
        <label className="pl-6 text-black w-full">
          <p className="hidden">{name}</p>
          <input
            type="number"
            min={v.values[0]}
            max={v.values[1]}
            placeholder={name}
            className="focus:outline-none inline-block w-full h-10 rounded-is px-5 border border-is-rev-duration"
            onChange={onChange}
          />
        </label>
      );
    default:
      return null;
  }
};

export default SearchBarMob;
