import d_lama from "./images/caratteristiche/diamlama.svg";
import d_foro from "./images/caratteristiche/diamforo.svg";
import altezza from "./images/caratteristiche/altezza.svg";
import base from "./images/caratteristiche/base.svg";
import n_denti from "./images/caratteristiche/ndenti.svg";
import spessore from "./images/caratteristiche/spessore.svg";
import d_punta from "./images/caratteristiche/d-punta.svg";
import l_utile from "./images/caratteristiche/l-utile.svg";
import a_macchina from "./images/caratteristiche/attacco-macchina.svg";

const attr2icon = {
  altezza,
  base,
  spessore,
  Raggio: d_lama,
  d_lama,
  d_foro,
  n_denti,
  d_punta,
  l_utile,
  a_macchina,
};

export { attr2icon };
