import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";
import { parseStory } from "../utils";

const useProductDict = () => {
  const res = useStaticQuery(graphql`
    query allProds {
      prods: allStoryblokEntry {
        nodes {
          name
          slug
          content
          field_component
          lang
        }
      }
    }
  `);

  const productDict = useMemo(
    () =>
      res.prods.nodes.reduce((obj, prod) => {
        const [type, k] = prod.field_component.split("-");
        if (type === "pagina") {
          const l = prod.lang === "default" ? "it" : prod.lang;
          !(l in obj) && (obj[l] = {});
          !(k in obj[l]) && (obj[l][k] = []);
          obj[l][k].push(parseStory(prod));
        }
        return obj;
      }, {}),
    [res.prods.nodes]
  );

  return productDict;
};

export default useProductDict;
