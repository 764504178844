import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import wa from "../../resources/images/wa.svg";
import viber from "../../resources/images/viber.svg";
import { categories, slugs2name, slugs2url } from "../../resources/categorie";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import t from "../../resources/translations";
/* global dataLayer */

const Footer = () => {
  const l = useContext(LanguageContext).locale;

  return (
    <footer>
      <div className="bg-white">
        <div className="flex justify-between pb-4 md:pb-9 px-8 pt-7 md:pt-11 is-cont">
          <Link to={t.ghLink[l]} className="md:hidden">
            <StaticImage
              src="../../resources/images/logo-is.png"
              alt="International Saws"
              width={170}
              layout="fixed"
            />
          </Link>
          <Link to={t.ghLink[l]} className="hidden md:block">
            <StaticImage
              src="../../resources/images/logo-is.png"
              alt="International Saws"
              width={226}
              layout="fixed"
              placeholder="blurred"
            />
          </Link>
          <div className="flex space-x-2 md:space-x-8">
            <a
              href="https://wa.me/393339896426"
              title="contact us on Whatsapp Business"
            >
              <img src={wa} alt="Whatsapp contact" className="w-10 md:w-16" />
            </a>
            <a
              href="viber://chat?number=%2B393339896426"
              title="contact us on Viber"
            >
              <img src={viber} alt="Viber contact" className="w-10 md:w-16" />
            </a>
          </div>
        </div>
      </div>
      <div className="bg-is-dark-gray">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-3 px-8 pb-7 md:pb-11 pt-3 md:pt-5 is-cont text-white">
          <div>
            <h4 className="is-p text-is-blue mb-3 font-bold col-span-2">
              International Saws s.r.l.
            </h4>
            <p>
              Sede: via Grecia 1 <br />
              64026 Roseto degli Abruzzi (TE) <br />
              Reg. Imprese e C.F. / P.iva 02156260503
            </p>
          </div>
          <div>
            <h4 className="is-p text-is-blue mb-3">Menu</h4>
            <nav>
              <ul className="grid grid-cols-2 gap-2">
                {Object.keys(categories).map((c) => (
                  <li key={c}>
                    <Link to={slugs2url[c][l]} className="hover:underline">
                      {slugs2name[c][l]}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link to={t.privacyLink[l]} className="hover:underline">
                    {t.privacyTitolo[l]}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <LeadGeneration />
        </div>
        <div className="flex justify-end is-cont py-4 text-white">
            Designed by The human side of digital:{" "}<a href={l === "it" ? "https://www.richclicks.it/" : "https://www.richclicks.co.uk/"} target="_blank" className="underline pl-1">RichClicks</a>
        </div>
      </div>
    </footer>
  );
};

const LeadGeneration = () => {
  const l = useContext(LanguageContext).locale;
  const [submitted, setSubmitted] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    try {
      const res = await fetch(
        "https://hooks.zapier.com/hooks/catch/769761/bk9emxc/",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
    } catch (err) {
      console.log(err);
    }
    dataLayer &&
      dataLayer.push({
        event: "richiesta_catalogo",
        ...data,
      });
    setSubmitted(true);
  };

  return submitted ? (
    <div>
      <p>{t.grazieLead[l]}</p>
    </div>
  ) : (
    <form onSubmit={submit}>
      <label>
        <h4 className="is-p text-is-blue mb-3">{t.richiediCatalogo[l]}</h4>
        <input
          type="email"
          className="block px-4 py-2 text-is-light-gray"
          placeholder="Email"
          name="email"
          required
        />
      </label>
      <button className="is-button mt-3">{t.scarica[l]}</button>
    </form>
  );
};

export default Footer;
