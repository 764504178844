import React from "react";
import arrowDown from "../../resources/images/arrow-down.svg";
import arrowDownW from "../../resources/images/arrow-down-white.svg";

const ToggleButton = ({ isOpen, onClick, type }) => {
  let partialString, img, imgClass;
  switch (type) {
    case "black":
      partialString = "h-5 w-5 bg-is-light-gray";
      img = arrowDownW;
      imgClass = "transform -rotate-90";
      break;
    case "big-black":
      partialString = "h-10 w-10 bg-is-light-gray";
      img = arrowDownW;
      imgClass = isOpen ? "transform rotate-180 w-3" : "w-3";
      break;
    case "white":
    default:
      partialString = "h-5 w-5 bg-is-dark-gray";
      img = arrowDownW;
      imgClass = isOpen ? "transform rotate-180" : "";
      break;
  }
  return (
    <button
      className={
        partialString +
        " is-arrow-shadow mr-3 rounded-full flex justify-center items-center transition-all duration-500"
      }
      onClick={onClick}
    >
      <img
        src={img}
        alt={isOpen ? "collapse section" : "expand section"}
        className={imgClass}
      />
    </button>
  );
};
ToggleButton.defaultProps = {
  type: "white",
};

export default ToggleButton;
