import React from "react";
import t from "../../resources/translations";
import { categories, slugs2name, slugs2url } from "../../resources/categorie";
import search from "../../resources/images/search-w.svg";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import { Link } from "gatsby";

const SearchButton = ({ activeCat, searchData, searchRef }) => {
  const l = useContext(LanguageContext).locale;
  return (
    <div className="rounded-full w-10 h-10 bg-is-blue grid place-content-center cursor-pointer">
      {activeCat === "tutti-prod" ? (
        <img src={search} alt={t.search[l]} />
      ) : (
        <Link
          to={
            slugs2url[activeCat][l] +
            "?" +
            Object.entries(searchData)
              .filter(([f, v]) => v !== null)
              .map(([f, v]) => `${f}=${v},${v}`)
              .join("&")
          }
          ref={searchRef}
        >
          <img src={search} alt={t.search[l]} />
        </Link>
      )}
    </div>
  );
};

export default SearchButton;
