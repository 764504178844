import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import phoneIc from "../../resources/images/phone.svg";
import searchIc from "../../resources/images/search.svg";
import burgerIc from "../../resources/images/burger-blu.svg";
import { Link } from "gatsby";
import { LanguageContext } from "../../context/language-context";
import t from "../../resources/translations";
import SearchBarDesk from "./search-bar-desk";
import SearchBarMob from "./search-bar-mob";
import { Popover } from "@headlessui/react";
import it from "../../resources/images/it.png";
import en from "../../resources/images/en.png";

const Topbar = ({ setIsMenuOpen }) => {
  const { locale, changeLang } = useContext(LanguageContext);
  const l = locale;
  return (
    <div className="bg-white">
      <div className="is-cont is-comm-flex items-stretch md:items-center md:py-6 px-3 md:shadow-none shadow-is">
        <Link className="md:hidden  py-5" to={t.ghLink[l]} title={t.gohome[l]}>
          <StaticImage
            src="../../resources/images/logo-is.png"
            alt="International Saws"
            loading="eager"
            placeholder="blurred"
            layout="fixed"
            width={113}
          />
        </Link>
        <Link className="hidden md:block" to={t.ghLink[l]} title={t.gohome[l]}>
          <StaticImage
            src="../../resources/images/logo-is.png"
            alt="International Saws"
            loading="eager"
            layout="fixed"
            width={226}
          />
        </Link>
        <SearchBarDesk />
        <div className="flex items-center gap-8">
          <div className="hidden md:flex flex-col items-center">
            <p className="is-p text-is-light-gray">{t.chiamaci[l]}</p>
            <p className="is-h4 text-is-blue">+39 085 201 50 91</p>
          </div>
          <div className="flex items-center gap-4 mr-2">
            <button
              className="cursor-pointer"
              onClick={() => {
                if (l !== "it") changeLang("it");
              }}
            >
              <img src={it} alt="Cambia lingua italiano" />
            </button>
            <button
              className="cursor-pointer"
              onClick={() => {
                if (l !== "en") changeLang("en");
              }}
            >
              <img src={en} alt="Change language to english" />
            </button>
          </div>
        </div>
        <div className="flex md:hidden items-center gap-3 mr-2">
          <a href="" title="Call us">
            <img src={phoneIc} alt="call us" />
          </a>
          <Popover as="div" className="self-end">
            {({ open }) => (
              <>
                <div
                  className={`border-b ${
                    open ? "border-is-search-gray" : "border-transparent"
                  } relative z-10 top-px`}
                >
                  <Popover.Button as="div" className="h-full">
                    <div
                      className={`${
                        open
                          ? "bg-is-search-gray border-is-blue"
                          : "border-transparent"
                      } border border-b-0 p-2.5 rounded-t-is pb-5 transition-colors duration-150 ease-out`}
                    >
                      <img src={searchIc} alt="open search bar" />
                    </div>
                  </Popover.Button>
                </div>
                <SearchBarMob />
              </>
            )}
          </Popover>
          <a
            href="#menu"
            title="Open menu"
            onClick={() => {
              setIsMenuOpen(true);
            }}
          >
            <img src={burgerIc} alt="open menu" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
