import React, { useState } from "react";
import ToggleButton from "./toggle-button";
import close from "../../resources/images/close.svg";
import {
  categories,
  slugs2name,
  slugs2url,
  subcat2urlpart,
} from "../../resources/categorie";
import { Link } from "gatsby";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import t from "../../resources/translations";

const MenuMob = ({ isOpen, setIsOpen }) => {
  const [menuState, setMenuState] = useState(
    Object.keys(categories).reduce(
      (obj, nex) => {
        obj[nex] = false;
        return obj;
      },
      {
        cats: true,
      }
    )
  );

  const toggleSubmenu = (name) => {
    console.log({ ...menuState, name: !menuState[name] });
    setMenuState((old) => ({ ...old, [name]: !old[name] }));
  };

  const catMenuHeight = Object.entries(categories)
    .map(([cat, sub]) => (menuState[cat] ? (1 + sub.length) * 60 : 60))
    .reduce((cum, nex) => cum + nex, 0);

  const l = useContext(LanguageContext).locale;

  return (
    <nav
      id="menu"
      className="w-full px-4 transition-all fixed top-0 bg-is-bg-gray text-white z-20 min-h-full md:hidden overflow-y-auto max-h-screen"
      style={{ left: isOpen ? "0" : "100%" }}
    >
      <div>
        <div className="is-comm-flex py-3.5 is-border-gray">
          <h3 className="text-is-blue is-h3b">{t.menu[l]}</h3>
          <a
            href=""
            title="close menu"
            className="mr-2"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            <img src={close} alt="close" />
          </a>
        </div>
        <div className="is-comm-flex py-4 is-border-gray">
          <h4 className="is-h4 text-is-blue">{t.categorieProd[l]}</h4>
          <ToggleButton
            isOpen={menuState["cats"]}
            onClick={() => {
              toggleSubmenu("cats");
            }}
          />
        </div>
        <div
          className={"transition-all overflow-hidden"}
          style={{
            /* maxHeight: menuState["cats"] ? catMenuHeight + "px" : "0px", */
            transitionDuration: catMenuHeight + "ms",
          }}
        >
          {Object.entries(categories).map(([cat, subCats]) => (
            <React.Fragment key={cat}>
              <div className="is-comm-flex py-4 pl-12 is-border-gray">
                <Link to={slugs2url[cat][l]} className="is-pb">
                  {slugs2name[cat][l]}
                </Link>
                {subCats.length > 0 && (
                  <ToggleButton
                    isOpen={menuState[cat]}
                    onClick={() => {
                      toggleSubmenu(cat);
                    }}
                  />
                )}
              </div>
              {subCats.length > 0 && (
                <div
                  className={
                    "transition-all overflow-hidden " +
                    "duration-" +
                    subCats.length * 75 +
                    " " +
                    (menuState[cat]
                      ? "max-h-" + subCats.length * 14
                      : "max-h-0")
                  }
                >
                  {subCats.map((subcat) => (
                    <div key={subcat} className="py-4 pl-24 is-border-gray">
                      <Link
                        to={`${slugs2url[cat][l]}${subcat2urlpart[subcat][l]}`}
                        className="is-p"
                      >
                        {slugs2name[subcat][l]}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="py-4 is-border-gray">
          <Link to={t.abLink[l]}>
            <h4 className="is-h4 text-is-blue">{t.about[l]}</h4>
          </Link>
        </div>
        <div className="py-4 is-border-gray">
          <Link to={t.conLink[l]}>
            <h4 className="is-h4 text-is-blue">{t.contact[l]}</h4>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default MenuMob;
