import { StaticImage } from "gatsby-plugin-image";
import React from "react";

// immagini BG
export const cat2bgImg = {
  coltello: (
    <StaticImage
      src="./images/bg-categorie/coltelli.webp"
      alt="categoria coltelli"
      placeholder="blurred"
      format={["auto"]}
      layout="fullWidth"
    />
  ),
  accessori: (
    <StaticImage
      src="./images/bg-categorie/accessori-mandrino-hsk.png"
      alt="categoria accessori"
      placeholder="blurred"
      format={["auto"]}
      layout="fixed"
      height={250}
    />
  ),
  elettroutensili: (
    <StaticImage
      src="./images/bg-categorie/utensili.webp"
      alt="categoria elettroutensili"
      placeholder="blurred"
      format={["auto"]}
      layout="fullWidth"
    />
  ),
  frese: (
    <StaticImage
      src="./images/bg-categorie/frese-z2ud-home-v2.png"
      alt="categoria frese"
      placeholder="blurred"
      format={["auto"]}
      layout="fullWidth"
    />
  ),
  lama: (
    <StaticImage
      src="./images/bg-categorie/lame-un3d-home.png"
      alt="categoria lame"
      placeholder="blurred"
      format={["auto"]}
      layout="fullWidth"
    />
  ),
  punte: (
    <StaticImage
      src="./images/bg-categorie/punte.webp"
      alt="categoria punte"
      placeholder="blurred"
      format={["auto"]}
      layout="fullWidth"
    />
  ),
  semilavorati: (
    <StaticImage
      src="./images/bg-categorie/coltelli.webp"
      alt="categoria semilavorati"
      placeholder="blurred"
      format={["auto"]}
      layout="fullWidth"
    />
  ),
  affilatura: (
    <>
      <StaticImage
        src="./images/bg-categorie/affilatura.png"
        alt="categoria servizi di affilatura"
        placeholder="blurred"
        format={["webp, auto"]}
        layout="fixed"
        height={180}
        className="-scale-x-100 hidden md:block"
      />
      <StaticImage
        src="./images/bg-categorie/affilatura.png"
        alt="categoria servizi di affilatura"
        placeholder="blurred"
        format={["webp, auto"]}
        layout="fixed"
        height={120}
        className="-scale-x-100 md:hidden"
      />
    </>
  ),
};
