import { categories, materials, slugs2name } from "./categorie";

const a_macchina_vals = [
  "2,5",
  "3",
  "3,5",
  "4",
  "5",
  "10",
  "10x26",
  "10x20",
  "10x30",
  "10x27",
  "10x42,5",
  "10x24",
  "10x35",
  "10x36",
  "10x25",
  "10x45",
  "10x40",
  "10x28",
];

const filtri = {
  coltello: {
    materiali: {
      type: "multi",
      values: Object.keys(materials),
      dict: materials,
    },
    base: {
      type: "range",
      values: [0.0, 50.0],
    },
    altezza: {
      type: "range",
      values: [0.0, 50.0],
    },
    spessore: {
      type: "range",
      values: [0.0, 20.0],
    },
    categoria: {
      type: "multi",
      values: categories.coltello,
      dict: slugs2name,
    },
  },
  lama: {
    materiali: {
      type: "multi",
      values: Object.keys(materials),
      dict: materials,
    },
    d_lama: {
      type: "range",
      values: [0.0, 500.0],
    },
    // n_denti: {
    //   type: "range",
    //   values: [0.0, 50.0],
    // },
    d_foro: {
      type: "range",
      values: [0.0, 300.0],
    },
    spessore: {
      type: "range",
      values: [0.0, 300.0],
    },
    categoria: {
      type: "multi",
      values: categories.lama,
      dict: slugs2name,
    },
  },
  frese: {
    materiali: {
      type: "multi",
      values: Object.keys(materials),
      dict: materials,
    },
    d_punta: {
      type: "range",
      values: [0.0, 200.0],
    },
    l_utile: {
      type: "range",
      values: [0.0, 200.0],
    },
    categoria: {
      type: "multi",
      values: categories.frese,
      dict: slugs2name,
    },
  },
  punte: {
    d_punta: {
      type: "range",
      values: [0.0, 250.0],
    },
    l_utile: {
      type: "range",
      values: [0.0, 250.0],
    },
    a_macchina: {
      type: "multi",
      values: a_macchina_vals,
      dict: null,
    },
    categoria: {
      type: "multi",
      values: categories.punte,
      dict: slugs2name,
    },
  },
  semilavorati: {
    base: {
      type: "range",
      values: [0.0, 50.0],
    },
    altezza: {
      type: "range",
      values: [0.0, 50.0],
    },
    spessore: {
      type: "range",
      values: [0.0, 20.0],
    },
    categoria: {
      type: "multi",
      values: categories.semilavorati,
      dict: slugs2name,
    },
  },
  accessori: {
    categoria: {
      type: "multi",
      values: categories.accessori,
      dict: slugs2name,
    },
  },
  elettroutensili: {
    // tipo: {
    //   type: "range",
    //   values: [0.0, 50.0],
    // },
    // marca: {
    //   type: "range",
    //   values: [0.0, 50.0],
    // },
  },
};

export default filtri;
