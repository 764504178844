import { Transition } from "@headlessui/react";
import { Link } from "gatsby";
import React, { useContext } from "react";
import { WishlistContext } from "../../context/wishlist-context";
import useWindowSize from "../../lib/use-window-size";
import close from "../../resources/images/close.svg";
import trash from "../../resources/images/trash.svg";
import wlLama from "../../resources/images/wl-lama.png";
import { attr2icon } from "../../resources/component-mapping";
import t from "../../resources/translations";
import { LanguageContext } from "../../context/language-context";
import { slugs2url } from "../../resources/categorie";
import { cat2bgImg } from "../../resources/images";

const Wishlist = () => {
  const { isMobile } = useWindowSize();
  const { wishlist, wishlistSize, isOpen, setIsOpen, setInviaRichiesta } =
    useContext(WishlistContext);
  const l = useContext(LanguageContext).locale;
  return (
    <Transition
      show={isOpen}
      enter="transition-all transform md:transform-none ease-out duration-300"
      enterFrom="-translate-y-full md:-right-full"
      enterTo="translate-y-0 md:right-0"
      entered="md:right-0"
      leave="transition-all transform md:transform-none ease-in duration-300"
      leaveFrom="translate-y-0 md:right-0"
      leaveTo="-translate-y-full md:-right-full"
      className="fixed w-full md:w-96 h-full md:shadow-is md:rounded-l-is bg-is-light-gray"
      style={{
        top: isMobile ? "60px" : "97px",
        height: isMobile ? "calc(100% - 60px)" : "calc(100% - 97px)",
        zIndex: "100000",
      }}
    >
      <aside className=" flex flex-col h-full">
        <div className="mx-4 flex justify-between items-center border-b border-white py-4 flex-shrink-0">
          <h2 className="text-white is-h3b">{t.preferiti[l]}</h2>
          <button
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <img src={close} alt="close" />
          </button>
        </div>
        <div className="overflow-auto mt-5 mb-4 py-2 px-4">
          <div className="flex flex-col gap-4">
            {Object.entries(wishlist).map(([k, o]) => (
              <WishlistCard key={k} k={k} o={o} />
            ))}
          </div>
        </div>
        {wishlistSize > 0 && (
          <div className="flex-shrink-0 pb-4 text-center px-4">
            <button
              className="is-button inline-block"
              onClick={() => {
                setInviaRichiesta(true);
              }}
            >
              {t.inviaRichiesta[l]}
            </button>
          </div>
        )}
      </aside>
    </Transition>
  );
};

const cat2bg = {
  coltello: wlLama,
};

const WishlistCard = ({ k, o }) => {
  const { removeFromWishlist } = useContext(WishlistContext);
  const l = useContext(LanguageContext).locale;
  return (
    <div className="h-52 is-card py-4 px-5 relative overflow-hidden">
      <div className="absolute w-full left-0 opacity-60 top-12">
        {o?.wishlistImg ? (
          <img
            src={o.wishlistImg.filename}
            alt={o.wishlistImg?.alt ?? ""}
            className="w-full"
          />
        ) : (
          cat2bgImg[o.cat]
        )}
      </div>
      <div className="flex w-full items-center justify-between gap-4 relative z-10">
        <h3 className="text-is-blue is-h4 overflow-hidden overflow-ellipsis whitespace-nowrap">
          <Link
            to={`${slugs2url[o.cat][l]}/${o.slug}`}
            title={`${t.vaiA[l]} ${o.name}`}
          >
            {o.titolo ?? o.name}
          </Link>
        </h3>
        <button
          onClick={() => {
            removeFromWishlist(k);
          }}
          className="flex-shrink-0"
        >
          <img
            src={trash}
            alt={`${t.rimuovi[l]} ${o.name} ${t.daiPreferiti[l]}`}
          />
        </button>
      </div>
      <div className="mt-5 w-full flex justify-center items-center flex-wrap gap-5 relative z-10">
        {Object.entries(o)
          .filter(([attr]) => attr in attr2icon)
          .map(([attr, val]) => (
            <div className="flex items-center gap-3">
              <img src={attr2icon[attr]} alt={t[attr][l]} className="w-6" />
              <div className="p-1.5 border border-white text-white">{val}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Wishlist;
