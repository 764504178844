import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import t from "../../resources/translations";

const FormFields = () => {
  const l = useContext(LanguageContext).locale;
  return (
    <>
      <div className="md:flex md:gap-10 text-black">
        <label className="md:flex-1">
          <span className="text-is-blue block my-2">{t.nome[l]}*</span>
          <input
            type="text"
            name="name"
            placeholder={t.nome[l]}
            className="border border-gray-500 py-2 px-4 w-full rounded-isinput"
            required
          />
        </label>
        <label className="md:flex-1">
          <span className="text-is-blue block my-2">{t.email[l]}*</span>
          <input
            type="email"
            name="email"
            placeholder={t.email[l]}
            className="border border-gray-500 py-2 px-4 w-full rounded-isinput"
            required
          />
        </label>
      </div>
      <label>
        <span className="text-is-blue block my-2">{t.oggetto[l]}</span>
        <input
          type="text"
          name="object"
          placeholder={t.oggetto[l]}
          className="border border-gray-500 py-2 px-4 w-full rounded-isinput text-black"
        />
      </label>
      <label>
        <span className="text-is-blue block my-2">{t.messaggio[l]}</span>
        <textarea
          name="message"
          placeholder={t.messaggio[l]}
          rows="4"
          className="border border-gray-500 py-2 px-4 w-full rounded-isinput text-black"
        />
      </label>
    </>
  );
};

export default FormFields;
