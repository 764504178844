import React, { useContext, useEffect, useState } from "react";
import Footer from "./footer";
import Topbar from "./topbar";
import MenuMob from "./menu-mob";
import MenuDesk from "./menu-desk";
import Wishlist from "./wishlist";
import { WishlistContext } from "../../context/wishlist-context";
import pref from "../../resources/images/pref.svg";
import InviaRichiesta from "./invia-richiesta";
import ContattaciForm from "./contattaci-form";
import { LanguageContext } from "../../context/language-context";
import t from "../../resources/translations";
import { Helmet } from "react-helmet";

const Layout = ({ children, initMenuOpen, location, setCanonical = true }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { setIsOpen } = useContext(WishlistContext);
  return (
    <div className="bg-is-bg-gray relative">
      {setCanonical && location?.pathname && (
        <Helmet>
          <link
            rel="canonical"
            href={"https://internationalsaws.it" + location?.pathname}
          />
        </Helmet>
      )}
      {/* <Helmet>
        <link
          rel="canonical"
          href={"https://internationalsaws.it" + location?.pathname}
        />
      </Helmet> */}
      <header className="sticky top-0 z-50 md:static">
        <Topbar setIsMenuOpen={setIsMenuOpen} setIsWishlistOpen={setIsOpen} />
        <MenuMob isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
        <MenuDesk initMenuOpen={initMenuOpen} setIsWishlistOpen={setIsOpen} />
      </header>
      <WishlistButtonMob setIsWishlistOpen={setIsOpen} />
      <Wishlist />
      <InviaRichiesta />
      <ContattaciForm />
      <div className="overflow-x-hidden">
        <main className="is-cont px-3.5">{children}</main>
      </div>
      <Footer />
      <CookieBanner />
    </div>
  );
};

const CookieBanner = () => {
  const [show, setShow] = useState(false);
  const l = useContext(LanguageContext).locale;
  useEffect(() => {
    if (
      !(
        window.localStorage &&
        window.localStorage.getItem("is-cookie") === "granted"
      )
    ) {
      setShow(true);
    }
  }, []);

  return (
    show && (
      <div className="max-w-full fixed z-40 bottom-0 left-0 p-8">
        <div className="bg-is-dark-gray border-white border-2 rounded-is p-6">
          <div className="flex items-center gap-6">
            <p>{t.cookieTesto[l]}</p>
            <button
              className="is-button"
              onClick={() => {
                if (window.localStorage) {
                  window.localStorage.setItem("is-cookie", "granted");
                  setShow(false);
                }
              }}
            >
              {t.cookieCta[l]}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

const WishlistButtonMob = ({ setIsWishlistOpen }) => {
  const { wishlistSize } = useContext(WishlistContext);
  return (
    <button
      className="flex justify-center items-center shadow-is rounded-b-full bg-is-dark-gray md:hidden fixed top-full z-30"
      style={{ right: "14px", width: "45px", height: "65px", top: "60px" }}
      onClick={() => {
        setIsWishlistOpen(true);
      }}
    >
      <div className="relative w-7">
        {wishlistSize > 0 && (
          <div className="bg-is-red-bright w-2 h-2 rounded-full absolute top-0 right-0" />
        )}
        <img src={pref} alt="wishlist" className="w-full" />
      </div>
    </button>
  );
};

Layout.defaultProps = {
  initMenuOpen: false,
};

export default Layout;
