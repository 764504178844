const t = {
  // 404
  nonTrovata: { it: "Qui non c'è una...", en: "You saw nothing here!" },
  torna: { it: "Torna alla ", en: "Go back to the" },

  // HEADER
  "tutti-prod": { it: "Tutti i prodotti", en: "All the products" },
  search: { it: "Cerca prodotti", en: "Search products" },
  chiamaci: { it: "Chiamaci subito!", en: "Call us!" },

  // MENU MOB
  menu: { it: "Menù", en: "Menu" },
  categorieProd: { it: "Categorie prodotti", en: "Product categories" },

  // FOOTER
  richiediCatalogo: {
    it: "Inserisci la mail per richiedere il catalogo",
    en: "Insert your email to receive the catalogue",
  },
  scarica: { it: "Scarica", en: "Download" },
  grazieLead: {
    it: "Grazie! Una email col nostro ultimo catalogo in allegato è in arrivo, se non la trovi prova a guardare nello spam",
    en: "Thank you! An email with our latest catalogue is on the way, if you can't find it try looking in the spam",
  },

  // LINKS
  abLink: { it: "/chi-siamo", en: "/en/about-us" },
  about: { it: "Chi siamo", en: "About us" },
  conLink: { it: "/contatti", en: "/en/contact-us" },
  contact: { it: "Contatti", en: "Contact us" },
  home: { it: "Home", en: "Home" },
  gohome: { it: "Vai all'homepage", en: "Go to the homepage" },
  ghLink: { it: "/", en: "/en" },
  catProd: { it: "Categorie prodotti", en: "Product categories" },
  seeAllProd: { it: "Vai a tutti i prodotti", en: "Go to all products" },
  frozLink: { it: "/trattamento-frozen", en: "/en/frozen-treatment" },
  frozen: { it: "Trattamento Frozen", en: "Frozen treatment" },
  metalLink: { it: "/metallo-duro", en: "/en/hard-metal" },
  metal: { it: "Metallo duro", en: "Hard metal" },
  bladeslLink: { it: "/le-nostre-lame", en: "/en/our-blades" },
  blades: { it: "Le nostre lame", en: "Our blades" },
  intro: { it: "Introduzione", en: "Introduction" },

  // HOME
  ctaTitolo: { it: "Tradizione e innovazione", en: "Tradition and innovation" },
  ctaTesto: {
    it: "International Saws è l’azienda italiana specializzata in lame circolari, metallo duro e utensili per la lavorazione di legno, plastica e alluminio. Garantiamo la possibilità di scegliere il grado di durezza del metallo in base al tipo di lavorazione per il quale l’utensile è stato concepito.",
    en: "International Saws is the Italian recognized brand specialised in circular saw blades, carbide and tools for wood, plastic and aluminium working. We guarantee the possibility of choosing the degree of metal hardness according to the type of work for which the tool has been designed.",
  },
  ctaBottone: {
    it: "Scrivici per saperne di più",
    en: "Contact us to find out more",
  },
  featTemp: { it: "Trattamento Frozen", en: "Frozen treatment" },
  featTempT: {
    it: "Il Trattamento Frozen è un rivestimento performante",
    en: "Frozen treatment is a performant covering",
  },
  featTempL: { it: "/trattamento-frozen", en: "/en/frozen-treatment" },
  featHard: { it: "Metallo duro", en: "Hard metal" },
  featHardT: {
    it: "Il metallo duro con cui è costituito il tagliente delle lame",
    en: "The carbide, from which the cutting edge is made, is the most important part of a tool",
  },
  featHardL: { it: "/metallo-duro", en: "/en/hard-metal" },
  featLame: { it: "Le nostre lame", en: "Our blades" },
  featLameT: {
    it: "Le principali caratteristiche della lame International Saws",
    en: "Main characteristics of International Saws blades",
  },
  featLameL: { it: "/le-nostre-lame", en: "/en/our-blades" },
  defaultCtaTitolo: {
    it: "Cerchi un esperto?",
    en: "Looking for an expert?",
  },
  defaultCtaBottone: { it: "Contattaci", en: "Contact us" },

  // CONTATTI
  lasciaMessaggio: { it: "Lasciaci un messaggio", en: "Leave a messagge" },
  indirizzo: { it: "Indirizzo", en: "Address" },
  telefono: { it: "Telefono", en: "Phone" },
  orari: { it: "Orari", en: "Opening hours" },
  daLunedi: { it: "Da lunedì a venerdì", en: "From Monday to Friday" },
  mattina: { it: "mattina", en: "morning" },
  pomeriggio: { it: "pomeriggio", en: "afternoon" },
  chiusoWeekend: {
    it: "Chiuso sabato e domenica",
    en: "Closed Saturday and Sunday",
  },
  contW: { it: "Contattaci su Whatsapp", en: "Contact us on Whatsapp" },
  contV: { it: "Contattaci su Viber", en: "Contact us on Viber" },

  // PREFERITI
  preferiti: { it: "Preferiti", en: "Wishlist" },
  vaiA: { it: "vai a", en: "go to" },
  rimuovi: { it: "rimuovi", en: "remove" },
  daiPreferiti: { it: "dai preferiti", en: "from the wishlist" },

  // FILTRI
  filtri: { it: "Filtri", en: "Filters" },
  chiudiFiltri: { it: "Chiudi i filtri", en: "Close the filters" },
  materiali: { it: "Materiali", en: "Materials" },
  categoria: { it: "Categoria", en: "Category" },
  noProdotti: {
    it: "Non abbiamo trovato prodotti nella categoria che rispettino questi filtri.",
    en: "We did not find any product in this category matching your filters.",
  },

  // PROPRIETÀ
  d_lama: { it: "Diametro lama", en: "Sawblade diameter" },
  n_denti: { it: "Numero denti", en: "Nr. of teeth" },
  d_foro: { it: "Diametro foro", en: "Bore diameter" },
  spessore: { it: "Spessore", en: "Thickness" },
  base: { it: "Base", en: "Base" },
  altezza: { it: "Altezza", en: "Height" },
  d_punta: { it: "Diametro punta", en: "Tip diameter" },
  l_utile: { it: "Lunghezza utile", en: "Working length" },
  a_macchina: { it: "Attacco macchina", en: "Machine" },

  // PRODOTTO
  scopriProd: {
    it: "Scopri questo prodotto della categoria",
    en: "Discover this product of the category",
  },
  contPerInfo: {
    it: "Contattaci per ulteriori informazioni!",
    en: "Contact us for any other info!",
  },
  descrizione: { it: "Descrizione", en: "Description" },
  specifiche: { it: "Specifiche", en: "Specification" },
  aggiungiWishlist: { it: "Aggiungi ai preferiti", en: "Add to wishlist" },
  aggiuntoWishlist: { it: "Aggiunto ai preferiti", en: "Added to wishlist" },
  inviaRichiesta: { it: "Invia una richiesta", en: "Send a request" },
  resettaFiltri: { it: "Resetta filtri", en: "Reset filters" },
  matLavorare: {
    it: "Prodotto adatto per la lavorazione di:",
    en: "Product suitable for the working of:",
  },
  selD_lama: {
    it: "Seleziona il diametro della lama",
    en: "Choose sawblade diameter",
  },
  selN_denti: {
    it: "Seleziona il numero di denti",
    en: "Select number of teeth",
  },
  selD_foro: {
    it: "Seleziona il diametro del foro",
    en: "Choose bore diameter",
  },
  selSpessore: { it: "Seleziona lo spessore", en: "Select the thickness" },
  selBase: { it: "Seleziona la base", en: "Select the base" },
  selAltezza: { it: "Seleziona l'altezza", en: "Select the height" },
  default: { it: "---", en: "---" },
  disponibiliSetup: {
    it: "disponibili con questo setup",
    en: "available with this setup",
  },

  // FORMS
  testoInviaRichiesta: {
    it: "Inviaci la tua lista di preferiti e i tuoi contatti, ti ricontatteremo appena possibile.",
    en: "Send us your wishlist and your contact, we will reach out as soon as possible.",
  },
  nome: { it: "Nome", en: "Name" },
  email: { it: "E-mail", en: "E-mail" },
  oggetto: { it: "Oggetto", en: "Subject" },
  messaggio: { it: "Messaggio", en: "Message" },
  invia: { it: "Invia", en: "Send" },
  contattaci: { it: "Contattaci", en: "Contact us" },
  testoContattaci: {
    it: "Qui puoi scriverci per qualsiasi chiarimento o semplicemente per metterti in contatto con noi.",
    en: "Here you can write us for any request or just to get in touch with us.",
  },
  testoGrazieContatto: {
    it: "Grazie per averci contattato! Ti risponderemo al più presto",
    en: "Thank you for contacting us! We will get back to you as soon as possible.",
  },
  hoCapito: {
    it: "Ho capito",
    en: "Got it",
  },

  // SERVIZI DI AFFILATURA
  testoAffilatura: {
    it: "Specifica nel messaggio il tipo di servizio e la quantità richiesta",
    en: "Specify in the message the type of service and the quantity required",
  },

  // COOKIE BANNER & PRIVACY POLICY
  cookieTesto: {
    it: "Questo sito utilizza solo cookie essenziali",
    en: "This website only uses essential cookies",
  },
  cookieCta: {
    it: "Ho capito",
    en: "I understand",
  },

  privacyTitolo: {
    it: "Privacy Policy",
    en: "Privacy Policy",
  },
  privacyLink: {
    it: "/privacy-policy",
    en: "/en/privacy-policy",
  },
};

export default t;
