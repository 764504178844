import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { LanguageContext } from "../../context/language-context";
import { categories, slugs2name, slugs2url } from "../../resources/categorie";
import t from "../../resources/translations";
import { Listbox } from "@headlessui/react";
import arrProd from "../../resources/images/arr_white.svg";
import filtri from "../../resources/filtri";
import SearchButton from "./search-button";
import { useRef } from "react";
import useProductDict from "../use-products-dict";
import { Link } from "gatsby";
import { cat2bgImg } from "../../resources/images";
import { generateSearchFilter } from "../../utils";

const SearchBarDesk = () => {
  const [activeCat, setActiveCat] = useState("tutti-prod");
  const [searchData, setSearchData] = useState(null);
  const l = useContext(LanguageContext).locale;
  const searchButtonRef = useRef();
  const prodsDict = useProductDict();

  const [suggestedProds, setSuggestedProds] = useState([]);

  useEffect(() => {
    if (activeCat === "tutti-prod" && typeof searchData === "string") {
      if (searchData.length > 1) {
        const prods = Object.values(prodsDict[l]).flatMap((i) => i);
        const fnc = generateSearchFilter(searchData);
        const scoredProds = prods.map(fnc);
        scoredProds.sort((a, b) => (b?.score ?? 0) - (a?.score ?? 0));
        setSuggestedProds(
          scoredProds.filter((p, i) => i < 10 && (p?.score ?? 0) > 0)
        );
      } else if (searchData.length < 1) {
        setSuggestedProds([]);
      }
    }
  }, [activeCat, searchData, prodsDict]);

  const onEnter = (e) => {
    console.log("called");
    if (e.keyCode === 13) {
      console.log("got enter");
      e.preventDefault();
      searchButtonRef?.current && searchButtonRef.current.click();
    }
  };

  //console.log(suggestedProds);
  return (
    <div className="items-center gap-3.5 hidden md:flex" onKeyUp={onEnter}>
      <div className="flex items-center overflow-y-visible">
        <Listbox
          value={activeCat}
          onChange={(v) => {
            setActiveCat(v);
            setSearchData(
              v === "tutti-prod"
                ? ""
                : Object.keys(filtri[v]).reduce((obj, f) => {
                    f !== "categoria" && f !== "materiali" && (obj[f] = null);
                    return obj;
                  }, {})
            );
          }}
          as="div"
          className="relative"
        >
          <Listbox.Button
            className="text-white bg-is-light-gray px-5 py-2.5 is-comm-flex gap-8 rounded-l-is border border-is-gray"
            style={{ height: "45px" }}
          >
            {activeCat === "tutti-prod"
              ? t[activeCat][l]
              : slugs2name[activeCat][l]}
            <img src={arrProd} alt="" />
          </Listbox.Button>
          <Listbox.Options
            className="bg-white border-is-range-slider border border-t-0 text-is-light-gray absolute right-0 flex flex-col"
            style={{ zIndex: 100001 }}
          >
            {["tutti-prod", ...Object.keys(categories)]
              .filter(
                (v) =>
                  v !== "affilatura" &&
                  v !== "elettroutensili" &&
                  v !== "accessori"
              )
              .map((v, idx) => (
                <Listbox.Option
                  key={v}
                  value={v}
                  className={
                    " py-2 border-is-range-slider border-t px-3 cursor-pointer hover:bg-gray-300 "
                  }
                >
                  {v === "tutti-prod" ? t[v][l] : slugs2name[v][l]}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        </Listbox>
        {activeCat === "tutti-prod" ? (
          <label
            className="md:w-72 px-4 text-black border-r border-y border-is-gray rounded-r-is flex items-center relative"
            style={{ height: "45px" }}
          >
            <p className="hidden">Cerca un prodotto</p>
            <input
              type="text"
              placeholder="Search..."
              autoComplete="off"
              name="search"
              className="focus:outline-none"
              value={searchData}
              onChange={(e) => {
                setSearchData(e.target.value);
              }}
            />
            {suggestedProds && suggestedProds.length > 0 && (
              <div className="absolute w-full top-full left-0 bg-is-dark-gray z-20">
                <ul>
                  {suggestedProds.map((p) => {
                    const cat = p.field_component.replace("pagina-", "");
                    return (
                      <li className="px-2 py-0.5 border-t border-white first:border-t-0 hover:bg-is-light-gray">
                        <Link
                          to={`${slugs2url[cat][l]}/${p.slug}`}
                          className="text-white flex items-center gap-2"
                        >
                          <div className="w-9">
                            {p?.content?.imgs[0] ? (
                              <img
                                src={p?.content?.imgs[0].filename}
                                alt={p?.content?.imgs[0]?.alt ?? ""}
                                className="h-9 mx-auto"
                              />
                            ) : (
                              cat2bgImg[cat]
                            )}
                          </div>
                          {p?.content?.titolo ?? p?.name ?? ""}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </label>
        ) : (
          Object.entries(filtri[activeCat])
            .filter(([f, v]) => f !== "categoria" && f !== "materiali")
            .map(([f, v]) => (
              <FiltInput
                f={f}
                v={v}
                key={f}
                name={t[f][l]}
                onChange={(e) => {
                  setSearchData((old) => ({
                    ...old,
                    [f]: e.target.value,
                  }));
                }}
              />
            ))
        )}
      </div>
      <SearchButton
        activeCat={activeCat}
        searchData={searchData}
        searchRef={searchButtonRef}
      />
    </div>
  );
};

const FiltInput = ({ f, v, name, onChange }) => {
  switch (v.type) {
    case "range":
      return (
        <label
          className="px-4 text-black bord-search flex items-center w-44"
          style={{ height: "45px" }}
        >
          <p className="hidden">{name}</p>
          <input
            type="number"
            min={v.values[0]}
            max={v.values[1]}
            placeholder={name}
            className="w-full focus:outline-none"
            onChange={onChange}
          />
        </label>
      );
    default:
      return null;
  }
};

export default SearchBarDesk;
