import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context";
import { WishlistContext } from "../../context/wishlist-context";
import t from "../../resources/translations";
import close from "../../resources/images/close-white.svg";
import FormFields from "./form-fields";
/* global dataLayer */

const ContattaciForm = () => {
  const { isContattaciOpen, setContattaci } = useContext(WishlistContext);
  const [isSubmitted, setSubmitted] = useState(false);

  const l = useContext(LanguageContext).locale;

  const submit = async (e) => {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.target).entries());
    data.sheet = "contattaci";
    try {
      const res = await fetch(
        "https://hooks.zapier.com/hooks/catch/769761/bk2jgke/",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );
    } catch (err) {
      console.log(err);
    }
    dataLayer &&
      dataLayer.push({
        event: "richiesta_contatto",
        ...data,
      });
    setSubmitted(true);
  };

  return (
    <Transition show={isContattaciOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[10000000] overflow-y-auto"
        onClose={() => {
          setContattaci(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-70" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md lg:max-w-screen-md my-8 overflow-hidden text-left align-middle transition-all shadow-xl bg-is-dark-gray rounded-is text-white py-4 px-4 md:py-5 md:px-10 relative z-10">
              <div className="flex justify-between items-start">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-bold text-is-blue mt-8 md:mt-0"
                >
                  {t.contattaci[l]}
                </Dialog.Title>
                <button
                  onClick={() => {
                    setContattaci(false);
                  }}
                >
                  <img src={close} alt="close" />
                </button>
              </div>
              {isSubmitted ? (
                <>
                  <div className="mt-2.5">
                    <p className="text-lg">{t.testoGrazieContatto[l]}</p>
                  </div>
                  <div className="mt-6 flex justify-center">
                    <button
                      className="is-button"
                      onClick={() => {
                        setContattaci(false);
                      }}
                    >
                      {t.hoCapito[l]}
                    </button>
                  </div>
                </>
              ) : (
                <form onSubmit={submit}>
                  <div className="mt-2.5">
                    <p className="text-lg">{t.testoContattaci[l]}</p>
                  </div>
                  <div className="mt-6">
                    <FormFields />
                  </div>
                  <div className="mt-6 flex gap-10 items-start">
                    <button className="is-button">{t.invia[l]}</button>
                    <label className="flex gap-1 items-center text-sm">
                      <input type="checkbox" name="privacy" required />
                      Privacy policy
                    </label>
                  </div>
                </form>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ContattaciForm;
